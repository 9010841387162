<template>
  <div class="yt-main" ref="manualBody">
    <div class="yt-header">
      <el-menu class="yt-menu" :default-active="activeRootIndex" mode="horizontal" @select="selectRootMenu">
        <el-menu-item index="1" :disabled="menuLoading">企业</el-menu-item>
        <el-menu-item index="0" :disabled="menuLoading">平台</el-menu-item>
      </el-menu>
      <div class="yt-header-toolbar">
        <el-input
          v-model="searchQuestionForm.questionName"
          placeholder="通过题目名称进行搜索"
          class="yt-search"
          @keydown.enter.native="searchQuestionBanks(false)"
        >
          <i slot="suffix" class="el-input__icon el-icon-search" @click="searchQuestionBanks(false)"></i>
        </el-input>
        <template v-if="activeRootIndex === '1'">
          <el-select
            v-model="searchQuestionForm.tagIds"
            placeholder="搜索题目标签"
            class="yt-search-select"
            filterable
            clearable
            multiple
            :collapse-tags="searchQuestionForm.tagIds.length > 2"
            @change="searchQuestionBanks(false)"
          >
            <el-option v-for="item in tagOptions" :key="item.tagId" :label="item.name" :value="item.tagId"> </el-option>
          </el-select>
        </template>
      </div>
    </div>
    <div class="yt-container">
      <div class="check-right" :style="{ width: drawer ? '259px' : '20px' }">
        <YTIcon class="left-svg" v-if="!drawer" :href="'#icon-shouqi'" @click="drawer = true" />
        <div :class="{ 'right-drawer': drawer }">
          <YTIcon class="right-svg" :href="'#icon-shouqi'" @click="drawer = false" v-if="drawer" />
          <div v-show="drawer" style="height: 100%;">
            <div class="header">
              <span>已选题数：{{ addQuestionList.length }}</span>
              <el-menu
                class="yt-menu"
                style="margin-left: 143px !important; margin-top: -20px"
                :default-active="type"
                mode="horizontal"
                @select="selectTypeMenu"
              >
                <el-menu-item index="1">全部</el-menu-item>
                <el-menu-item index="0">题型</el-menu-item>
              </el-menu>
            </div>
            <div class="line" />
            <div class="content">
              <div v-show="type === '1'" class="question-info" v-for="(item, index) in addQuestionList" :key="index">
                <div class="question-title">
                  <span style="margin-right: 5px;white-space: nowrap">{{ index + 1 }}.</span>
                  <div v-html="item.stem.substr(0, 70)"></div>
                </div>
                <div class="del">
                  <YTIcon href="#icon-shanchu" @click="delQuestion(item.id)" />
                </div>
              </div>
              <div v-show="type === '0'">
                <div class="question-type">
                  <div v-for="(q, t) in questionSortList" :key="t">
                    <div>
                      <div class="yt-flex-layout" style="justify-content: space-between;margin-bottom: 10px" v-if="q.question.length > 0">
                        <div class="yt-flex-layout" style="align-items: center">
                          <div class="type" />
                          <span>{{ q.label }}: {{ q.question.length }}</span>
                          <div class="del-type">
                            <YTIcon href="#icon-shanchu" @click="delQuestionType(t)" />
                          </div>
                        </div>
                      </div>
                      <div class="question-sort" v-for="(qs, sIndex) in q.question" :key="sIndex">
                        <div class="question-title">
                          <span style="margin-right: 5px;white-space: nowrap">{{ sIndex + 1 }}.</span>
                          <div v-html="qs.stem.substr(0, 70)"></div>
                        </div>
                        <div class="del">
                          <YTIcon href="#icon-shanchu" @click="delQuestion(qs.id)" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="question-bank-content">
        <el-tabs class="yt-menu" v-model="activeIndex" :before-leave="beforeHandle">
          <el-tab-pane name="1">
            <div slot="label" class="label">
              <div class="icon" :style="{ background: activeIndex === '1' ? '#448bff' : '' }">
                <YTIcon :href="'#icon-tiku2'" :class="{ selectStyle: activeIndex === '1' }"></YTIcon>
              </div>
              <span>题库列表</span>
            </div>
          </el-tab-pane>
          <el-tab-pane name="2" v-if="activeRootIndex === '1' && activeIndex !== '3'">
            <div slot="label" class="label">
              <div class="icon" :style="{ background: activeIndex === '2' ? '#448bff' : '' }">
                <YTIcon :href="'#icon-zhishidian3'" :class="{ selectStyle: activeIndex === '2' }"></YTIcon>
              </div>
              <span>知识点</span>
            </div>
          </el-tab-pane>
          <el-tab-pane name="3" v-if="activeRootIndex === '1' && activeIndex !== '2' && activeIndex !== '1'">
            <div slot="label" class="label">
              <div class="icon" :style="{ background: activeIndex === '3' ? '#448bff' : '' }">
                <YTIcon :href="'#icon-fenlei'" :class="{ selectStyle: activeIndex === '3' }"></YTIcon>
              </div>
              <span>技能</span>
            </div>
          </el-tab-pane>
          <el-dropdown placement="bottom-start" @command="handleCommand">
            <YTIcon :href="'#icon-gengduo-shuxiang'" style="background: #FFFFFF"></YTIcon>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="2" style="padding: 0 30px">知识点</el-dropdown-item>
              <el-dropdown-item command="3" style="padding: 0 30px">技能</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-tabs>
        <!--题库列表-->
        <template v-if="activeIndex === '1'">
          <el-input v-model="keyword" class="yt-search search" placeholder="通过名称/标签搜索题库" @keydown.enter.native="searchQuestionBanks">
            <i slot="suffix" class="el-input__icon el-icon-search" @click="searchQuestionBanks"></i>
          </el-input>
          <div class="list-container" :style="{ height: 'calc(100% - 106px)' }">
            <div class="list">
              <div
                v-for="(item, index) in questionBankList"
                :key="index"
                :class="['item', activeBankIndex === index ? 'active' : '']"
                :style="{ border: item.hasOwnProperty('check') ? '1px solid #438bff' : '' }"
                @click="handleClickQuestionBank(index, item.questionBankId)"
              >
                <template v-if="item.hasOwnProperty('cover')">
                  <el-image class="cover" :src="item.cover">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </template>
                <template v-else>
                  <div class="cover"></div>
                </template>
                <div style="width: calc(100% - 39px);">
                  <div class="row">
                    <p>{{ item.name }}</p>
                    <div class="tab-container" v-if="activeRootIndex === '1' && item.hasOwnProperty('tabs') && item.tabs.length > 0">
                      <div class="item">
                        <div>{{ item.tabs[0].name }}</div>
                      </div>
                      <el-popover placement="top" title="题库标签" width="184" trigger="hover">
                        <div class="tab-list">
                          <div v-for="(tab, index) in item.tabs" :key="index" class="item">
                            <div>{{ tab.name }}</div>
                          </div>
                        </div>
                        <div slot="reference" class="dots">
                          <YTIcon :href="'#icon-gengduo1'"></YTIcon>
                        </div>
                      </el-popover>
                    </div>
                  </div>
                  <div class="row">
                    <div style="max-width: 134px">
                      <el-tooltip effect="dark" :content="item.questionCount.toString()" placement="top" :open-delay="1000">
                        <span style="padding-right: 8px">数量: {{ item.questionCount }}</span>
                      </el-tooltip>
                      <el-tooltip v-if="activeRootIndex === '1'" effect="dark" :content="item.creatorName" placement="top" :open-delay="1000">
                        <span style="border-left: 1px solid #000000; padding-left: 8px">创建者: {{ item.creatorName }}</span>
                      </el-tooltip>
                    </div>
                    <div style="width: 70px">
                      <span>{{ $formatTime(item.createdTime, 'yyyy-MM-dd') }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="activeIndex === '3'">
          <el-input v-model="skillKeyword" class="yt-search search" placeholder="通过技能名称筛选" @keydown.enter.native="toFilterNode('skill-tree')">
            <i slot="suffix" class="el-input__icon el-icon-search" @click="toFilterNode('skill-tree')"></i>
          </el-input>
          <div class="list-container" :style="{ height: fullscreen ? '558px' : '369px' }" v-loading="skillLoading" element-loading-text="加载中">
            <el-tree
              key="skill"
              :data="skillsData"
              :highlight-current="true"
              node-key="value"
              ref="skill-tree"
              :filter-node-method="filterNode"
              check-strictly
              @node-click="handleNodeClick"
            >
              <div slot-scope="{ node, data }" class="tree-node">
                <el-tooltip effect="dark" :content="node.label" :disabled="node.label.length < 10" placement="top-start">
                  <div class="el-tree-node__label">{{ node.label.substr(0, 10) }}</div>
                </el-tooltip>
                <el-tooltip effect="dark" :content="`技能试题总数：${data.count} 已选题数：${data.selectCount}`" placement="top-start">
                  <div>
                    <span :class="data.selectCount > 0 ? 'fontColor' : ''">{{ data.selectCount }}</span
                    >/{{ data.count }}
                  </div>
                </el-tooltip>
              </div>
            </el-tree>
          </div>
          <div class="footer">
            <el-button size="small" @click="resetCheckedNodes('skill-tree')">
              <div>
                <YTIcon :href="'#icon-zhongzhi'"></YTIcon>
                <p>重置</p>
              </div>
            </el-button>
          </div>
        </template>
        <template v-else>
          <el-input
            v-model="knowledgePointKeyword"
            class="yt-search search"
            placeholder="通过知识点名称筛选"
            @keydown.enter.native="toFilterNode('knowledge-tree')"
          >
            <i slot="suffix" class="el-input__icon el-icon-search" @click="toFilterNode('knowledge-tree')"></i>
          </el-input>
          <div class="list-container" :style="{ height: fullscreen ? '558px' : '424px' }">
            <el-tree
              :data="knowledgePoints"
              :props="knowledgePointProps"
              node-key="value"
              ref="knowledge-tree"
              :filter-node-method="filterNode"
              show-checkbox
              check-strictly
              @check="handleClickTreeNode"
            >
              <div class="tree-node" slot-scope="{ node, data }">
                <p>{{ node.label }}</p>
                <div>{{ data.knowQuestionCount }} / {{ data.questionCount }}</div>
              </div>
            </el-tree>
          </div>
          <!-- <div class="footer">
        <el-button size="small" @click="resetCheckedNodes('knowledge-tree')">
          <div>
            <YTIcon :href="'#icon-zhongzhi'"></YTIcon>
            <p>重置</p>
          </div>
        </el-button>
      </div> -->
        </template>
      </div>
      <div class="question-content" :style="{ height: '100%' }">
        <!--筛选-->
        <div
          class="filter-container"
          :style="{
            height: activeRootIndex === '1' ? (isPackUp ? '120px' : '50px') : '87px',
            overflow: 'hidden'
          }"
        >
          <div v-show="activeRootIndex === '1'" class="pack-up" @click="isPackUp = !isPackUp">
            <p>{{ !isPackUp ? '展开' : '收起' }}</p>
            <YTIcon :class="['icon', isPackUp ? 'active' : '']" :href="'#icon-open'"></YTIcon>
          </div>
          <template v-for="(filter, key, index) in filters">
            <div v-if="activeRootIndex === '1' || index !== 1" :key="index" class="row">
              <div class="label">{{ filter.label }}:</div>
              <template v-if="key !== 'other'">
                <div
                  v-for="(child, fIndex) in filter.children"
                  :key="fIndex"
                  :class="[
                    'item default-item',
                    {
                      active: searchQuestionForm.searchForm[key] === child.value
                    }
                  ]"
                  @click="handleClickFilter(key, child.value)"
                >
                  {{ child.label }}
                </div>
                <template v-if="index === 1">
                  <div class="checkbox-container">
                    <el-checkbox
                      v-if="searchQuestionForm.searchForm.questionType === '5'"
                      v-model="searchQuestionForm.searchForm.hasTestCase"
                      @change="reSearchQuestions"
                      >无测试用例</el-checkbox
                    >
                  </div>
                </template>
              </template>
              <template v-else>
                <div v-for="(child, fIndex) in filter.children" :key="fIndex" class="item other-item">
                  <!--排序-->
                  <template v-if="child.type === 'sort'">
                    <div @click="child.function">
                      {{ child.label }}
                      <YTIcon
                        :href="
                          searchQuestionForm.searchForm.useCountDesc === ''
                            ? '#icon-moren'
                            : searchQuestionForm.searchForm.useCountDesc
                            ? '#icon-jiangxu'
                            : '#icon-shengxu'
                        "
                      />
                    </div>
                  </template>
                  <!--下拉菜单-->
                  <template v-else-if="child.type === 'singleSelect'">
                    <el-dropdown @command="child.function" placement="bottom-start">
                      <span class="select">{{ child.label }}<i class="el-icon-arrow-down el-icon--right"></i></span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                          v-for="(option, oIndex) in child.options"
                          :key="oIndex"
                          :command="option.value"
                          :class="{
                            active: searchQuestionForm.searchForm[child.property] === option.value
                          }"
                        >
                          {{ option.label }}
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </template>
                  <!--多选下拉菜单-->
                  <template v-else-if="activeRootIndex === '1' && child.type === 'multipleSelect'">
                    <el-dropdown trigger="click" @command="child.function" placement="bottom-start" :hide-on-click="false">
                      <span class="select">{{ child.label }}<i class="el-icon-arrow-down el-icon--right"></i></span>
                      <el-dropdown-menu slot="dropdown" style="max-height: calc(100vh - 458px);">
                        <el-input
                          v-model="labelDownKeyword"
                          class="yt-search search"
                          style="margin-top: 12px;margin-bottom: 0"
                          placeholder="通过标签名称筛选"
                          @keydown.enter.native="toFilterLabel"
                        >
                          <i slot="suffix" class="el-input__icon el-icon-search" @click="toFilterLabel"></i>
                        </el-input>
                        <p
                          :style="{
                            color: searchQuestionForm.searchForm.hasQuestionTag ? '#4c90ff' : '#606266',
                            backgroundColor: searchQuestionForm.searchForm.hasQuestionTag ? '#edf4ff' : ''
                          }"
                          class="knowledge"
                          @click="setLabelSearchQuestions"
                        >
                          未绑定
                        </p>
                        <el-checkbox-group @change="searchQuestionForm.searchForm.hasQuestionTag = false" class="checkBox" v-model="child.value">
                          <el-checkbox v-for="option in child.options" :label="option.value" :key="option.label">{{ option.label }}</el-checkbox>
                        </el-checkbox-group>
                        <div class="footer">
                          <el-button type="primary" @click="toSearchByLabelKnowledge(true)">确定</el-button>
                          <el-button size="small" @click="toSearchByLabelKnowledge(false)">
                            <div>
                              <YTIcon :href="'#icon-zhongzhi'"></YTIcon>
                              <p>重置</p>
                            </div>
                          </el-button>
                        </div>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </template>
                  <template v-else-if="activeRootIndex === '1' && child.type === 'tree' && activeIndex !== '2'">
                    <el-dropdown trigger="click" placement="bottom-start">
                      <span class="select">{{ child.label }}<i class="el-icon-arrow-down el-icon--right"></i></span>
                      <el-dropdown-menu slot="dropdown">
                        <el-input
                          v-model="knowledgePointDownKeyword"
                          class="yt-search search"
                          style="margin-top: 12px;margin-bottom: 0"
                          placeholder="通过知识点名称筛选"
                          @keydown.enter.native="toFilterNode('knowledge-tree-down')"
                        >
                          <i
                            slot="suffix"
                            class="el-input__icon el-icon-search"
                            @click="toFilterNodeDown('knowledge-tree-down', knowledgePointDownKeyword)"
                          ></i>
                        </el-input>
                        <p
                          :style="{
                            color: searchQuestionForm.searchForm.hasKnowledge ? '#4c90ff' : '#606266',
                            backgroundColor: searchQuestionForm.searchForm.hasKnowledge ? '#edf4ff' : ''
                          }"
                          class="knowledge"
                          @click="setSearchQuestions"
                        >
                          未绑定
                        </p>
                        <div class="list-container">
                          <el-tree
                            :data="knowledgePointsDown"
                            :props="knowledgePointProps"
                            node-key="value"
                            ref="knowledge-tree-down"
                            :filter-node-method="filterNode"
                            @check-change="searchQuestionForm.searchForm.hasKnowledge = false"
                            show-checkbox
                            check-strictly
                          >
                          </el-tree>
                        </div>
                        <div class="footer">
                          <el-button type="primary" @click="toSearchByKnowledge">确定</el-button>
                          <el-button size="small" @click="resetCheckedNodesDown('knowledge-tree-down')">
                            <div>
                              <YTIcon :href="'#icon-zhongzhi'"></YTIcon>
                              <p>重置</p>
                            </div>
                          </el-button>
                        </div>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </template>
                  <template v-else-if="activeRootIndex === '1' && child.type === 'skillTree' && activeIndex !== '3'">
                    <el-dropdown trigger="click" placement="bottom-start">
                      <span class="select">{{ child.label }}<i class="el-icon-arrow-down el-icon--right"></i></span>
                      <el-dropdown-menu slot="dropdown">
                        <el-input
                          v-model="skillDownKeyword"
                          class="yt-search search"
                          placeholder="通过技能名称筛选"
                          style="margin-top: 12px;margin-bottom: 0"
                          @keydown.enter.native="skillToFilterNode('skill-tree-down')"
                        >
                          <i
                            slot="suffix"
                            class="el-input__icon el-icon-search"
                            @click="skillToFilterNodeDown('skill-tree-down', skillDownKeyword)"
                          ></i>
                        </el-input>
                        <p
                          :style="{
                            color: searchQuestionForm.searchForm.hasAbility ? '#4c90ff' : '#606266',
                            backgroundColor: searchQuestionForm.searchForm.hasAbility ? '#edf4ff' : ''
                          }"
                          class="knowledge"
                          @click="setSkillSearchQuestions"
                        >
                          未绑定
                        </p>
                        <div class="list-container">
                          <el-tree
                            :data="skillDown"
                            node-key="value"
                            ref="skill-tree-down"
                            :filter-node-method="filterNode"
                            @check-change="searchQuestionForm.searchForm.hasAbility = false"
                            show-checkbox
                          >
                          </el-tree>
                        </div>
                        <div class="footer">
                          <el-button type="primary" @click="toSearchByAbility">确定</el-button>
                          <el-button size="small" @click="resetCheckedNodesDown('skill-tree-down')">
                            <div>
                              <YTIcon :href="'#icon-zhongzhi'"></YTIcon>
                              <p>重置</p>
                            </div>
                          </el-button>
                        </div>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </template>
                </div>
              </template>
            </div>
          </template>
        </div>
        <!--全选-->
        <!-- <div class="select-all" v-if="activeRootIndex === '1' && questionList.length !== 0"> -->
        <!-- <el-checkbox :indeterminate="isIndeterminate" v-model="isSelectAll" @change="handleCheckAllChange">全选题目</el-checkbox> -->
        <div class="select-all" v-if="questionList.length" style="margin-top: 5px">
          <div>
            <el-button size="small" type="primary" style="margin-right: 10px;" @click="addAbility">全选</el-button>
            <span style="color: #448BFF;margin-right: 50px; cursor: pointer">已选题数:{{ idSet.length }}</span>
          </div>
        </div>
        <!-- </div> -->
        <!--题目列表-->
        <div
          class="question-container"
          :style="{ height: isPackUp ? 'calc(100% - 161px)' : 'calc(100% - 91px)' }"
          v-loading="questionListLoading"
          element-loading-text="加载中"
        >
          <div
            class="list"
            style="height: calc(100% - 45px)"
            :style="{
              overflowY: questionListLoading ? 'hidden' : 'auto'
            }"
          >
            <!--题目卡片-->
            <template v-if="questionList.length > 0">
              <div v-for="(question, index) in questionList" :key="index" class="item">
                <el-checkbox class="checkbox" v-model="question.isChecked" @change="handleCheckQuestion($event, question)" />
                <div style="width: 100%">
                  <!--题目卡片头部-->
                  <div class="header">
                    <div class="question-type">
                      {{ ytConstant.questionType.getLabel(question.questionType)[0] }}
                    </div>
                    <el-popover
                      placement="top"
                      width="184"
                      trigger="hover"
                      popper-class="paper-info"
                      :disabled="question.status !== 0"
                      @show="showAudit(question.id)"
                    >
                      <div :id="'audit-info-' + question.id">
                        <div class="paper-info-item">
                          审核人:
                          {{
                            questionAuditInfo[question.id] && questionAuditInfo[question.id].hasOwnProperty('auditorName')
                              ? questionAuditInfo[question.id].auditorName
                              : ''
                          }}
                        </div>
                        <div class="paper-info-item">
                          审核时间:
                          {{ questionAuditInfo[question.id] ? $formatTime(questionAuditInfo[question.id].auditTime, 'yyyy-MM-dd') : '' }}
                        </div>
                      </div>
                      <p slot="reference" class="audit">
                        <YTIcon :href="auditIcons[question.status]"></YTIcon>
                      </p>
                    </el-popover>
                  </div>
                  <QuestionCard
                    :question="{
                      ...question,
                      index: index
                    }"
                    :options="{
                      showSize: showSize
                    }"
                  >
                  </QuestionCard>
                  <!--题目卡片底部-->
                  <div class="footer">
                    <div>
                      <el-popover
                        placement="top"
                        width="184"
                        trigger="hover"
                        popper-class="paper-info"
                        :disabled="question.status !== 0"
                        @show="showAudit(question.id)"
                      >
                        <div :id="'audit-info-' + question.id">
                          <div class="paper-info-item">
                            审核人:
                            {{
                              questionAuditInfo[question.id] && questionAuditInfo[question.id].hasOwnProperty('auditorName')
                                ? questionAuditInfo[question.id].auditorName
                                : ''
                            }}
                          </div>
                          <div class="paper-info-item">
                            审核时间:
                            {{ questionAuditInfo[question.id] ? $formatTime(questionAuditInfo[question.id].auditTime, 'yyyy-MM-dd') : '' }}
                          </div>
                        </div>
                      </el-popover>
                      <p class="inactive">难度系<span style="letter-spacing: 5px">数:</span>{{ question.difficulty / 10 }}</p>
                      <p>{{ ytConstant.difficultType.getLabel(question.difficulty) }}</p>
                      <el-popover
                        placement="top"
                        width="237"
                        trigger="hover"
                        popper-class="paper-info"
                        :disabled="question.useCount === 0"
                        @show="showPaperInfo(question)"
                      >
                        <div class="paper-info-container" :id="'paper-info-' + question.id" :style="{ height: question.useCount * 32 + 'px' }">
                          <div v-for="(paper, index) in questionPaperInfo[question.id]" :key="index" class="paper-info-item">
                            <p>{{ index + 1 }}.{{ paper.name }}</p>
                            <p>
                              {{ $formatTime(paper.createTime, 'yyyy-MM-dd') }}
                            </p>
                          </div>
                        </div>
                        <p slot="reference" class="popover-reference">使用次<span style="letter-spacing: 5px">数:</span>{{ question.useCount }}次</p>
                      </el-popover>
                      <el-popover placement="top" width="237" trigger="hover" popper-class="paper-info">
                        <div class="paper-info-item">预计完成时间： {{ question.expectedTime }} 分钟</div>
                        <div class="paper-info-item">创建人： {{ question.name }}</div>
                        <div class="paper-info-item">创建时间： {{ question.createdTime }}</div>
                        <YTIcon slot="reference" :href="'#icon-gengduo1'"></YTIcon>
                      </el-popover>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <div
              class="empty"
              :style="{ height: activeRootIndex === '1' ? (isPackUp ? 'calc(100vh - 315px)' : 'calc(100vh - 320px)') : 'calc(100vh - 316px)' }"
              v-else
            >
              <template v-if="!questionListLoading" :style="height">
                <YTIcon :href="'#icon-zanwushuju'"></YTIcon>
              </template>
            </div>
          </div>
          <div class="page">
            <Page
              class="yt-page"
              :class="'yt-page-' + (total.toString().length > 1 ? total.toString().length : 2)"
              :total="total"
              :current="pageNum + 1"
              :page-size-opts="[5, 10, 20, 50]"
              :page-size="pageSize"
              show-elevator
              show-sizer
              show-total
              @on-change="changePage"
              @on-page-size-change="changePageSize"
            />
          </div>
        </div>
      </div>
    </div>
    <el-dialog class="prompt-info" title="提示" width="520px" top="35vh" append-to-body :visible.sync="stateManual">
      <div class="prompt">
        <div class="format">
          <YTIcon :href="'#icon-jingshi1'" slot="reference" style="width:19px;height:19px;"></YTIcon>
          <span>{{
            `您当前已经选择了${idSet.length}道题
    切换标签会清空当前选择的试卷题目，是否继续`
          }}</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="prompt-bottom">
          <el-button class="el-button" type="primary" size="small" @click="paperRelation">确定</el-button>
          <el-button class="el-button" size="small" @click="stateManual = false">取消</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import QuestionCard from '@components/common/version2/QuestionCard'
import questionBankApi from '@api/questionBank'
import questionApi from '@api/question'
import tagApi from '@api/tag'
import abilityApi from '@api/ability'
import questionAbilityApi from '@api/questionAbility'
export default {
  name: 'QuestionList',
  components: {
    QuestionCard,
    YTIcon
  },
  /**
   * @param notQuestionType 需要过滤的题型
   */
  props: ['fullscreen', 'notQuestionType'],
  data() {
    return {
      dataLoading: true,
      drawer: true,
      tickData: null,
      activeRootIndex: '1',
      keyword: '',
      isPackUp: true,
      type: '1',
      questionSortList: [
        { label: '判断题', question: [] },
        { label: '单选题', question: [] },
        { label: '多选题', question: [] },
        { label: '填空题', question: [] },
        { label: '简答题', question: [] },
        { label: '代码题', question: [] }
      ],
      typeLoading: false,
      searchQuestionForm: {
        questionName: '',
        questionBankId: '',
        tagIds: [],
        searchForm: {
          questionType: '', //题型
          questionStatus: '', //状态
          difficultyType: null, //难度
          useCountDesc: '', //使用次数
          questionTagIds: [], //标签
          knowledgePointIds: [], //知识点
          hasKnowledge: false,
          hasTestCase: false,
          hasQuestionTag: false,
          abilities: [], //技能
          hasAbility: false
        }
      },
      skillKeyword: '', //技能菜单搜索
      skillDownKeyword: '', //技能筛选搜索
      skillsId: '', //技能id
      skillsData: [], //左侧技能树
      skillDown: [], //技能筛选树
      activeBankIndex: 0,
      tagOptions: [], //下拉菜单标签列表
      activeIndex: '1', //左侧题库: 1/知识点: 2
      questionBankList: [], //题目列表
      moveQuestionBankList: [],
      knowledgePointKeyword: '', //知识点菜单搜索
      knowledgePoints: [], //知识点菜单树
      labelDownKeyword: '', //标签筛选搜索
      labeData: [], //标签数据
      knowledgePointDownKeyword: '', //知识点筛选搜索
      knowledgePointsDown: [], //知识点筛选树
      knowledgePointProps: {
        label: 'name',
        value: 'id',
        children: 'children'
      },
      filters: {
        questionType: {
          label: '题型',
          children: [{ label: '全部', value: '' }, ...this.ytConstant.questionType.getMenu()]
        },
        questionStatus: {
          label: '状态',
          children: [
            { label: '全部', value: '' },
            { label: '已审核', value: '0' },
            { label: '未审核', value: '2' },
            { label: '草稿', value: '1' }
          ]
        },
        other: {
          label: '其他',
          children: [
            {
              label: '使用次数',
              property: 'useCountDesc',
              type: 'sort',
              function: this.sortByUseCount
            },
            {
              label: '难度筛选',
              property: 'difficultyType',
              type: 'singleSelect',
              function: this.selectDifficulty,
              options: [...this.ytConstant.difficultType.getMenu()]
            },
            {
              label: '标签筛选',
              property: 'questionTagIds',
              type: 'multipleSelect',
              function: this.selectQuestionTag,
              options: [],
              value: []
            },
            { label: '知识点筛选', property: 'knowledgePointIds', type: 'tree' },
            { label: '技能筛选', property: ' skillPointIds', type: 'skillTree' }
          ]
        }
      },
      skillLoading: true, //技能加载
      skillUpdateKeyword: '', //题目技能搜索关键词
      isSelectAll: false, //是否全选
      isIndeterminate: false, //是否开启不确定全选
      questionList: [], //题目列表
      questionListLoading: true, //是否加载中
      questionSelection: [], //被选中题目
      questionPaperInfo: {}, //悬停显示使用了题目的试卷列表
      questionAuditInfo: {}, //悬停显示题目审核信息
      auditIcons: ['#icon-yishenhe', '#icon-caogao', '#icon-weishenhe'], //审核图标
      difficulties: [
        { label: '简单', value: '0' },
        { label: '中等', value: '1' },
        { label: '困难', value: '2' }
      ],
      pageNum: 0,
      pageSize: 10,
      total: 0,
      totalLeft: ['two', 'three', 'four', 'five'],
      toAuditQuestionId: '', //待审核的题目id
      toDeleteQuestionIds: [], //待删除的题目id
      moveToBankId: '', //移动到的新题库
      modifyForm: {},
      showSize: 1, //根据屏幕宽度展示标签的个数
      tagKeyword: '', //编辑题目标签时的关键词
      tagCloseTimeOut: null, //题目标签定时器
      addTagIndex: -1,
      knowledgePointUpdateKeyword: '', //题目知识点搜索关键词
      knowledgePointsUpdate: [], //编辑知识点用的树
      tagUpdateKeyword: '', //题目标签搜索关键词
      tagsUpdate: [], //编辑标签用的树
      menuLoading: false, //企业平台菜单防抖
      codeTypes: {
        java: 'java',
        JavaScript: 'javascript',
        c: 'c',
        'c++': 'cpp',
        python: 'python',
        bash: 'shell'
      },
      idSet: [], //存储选中的题目id
      stateManual: false,
      questionSimpleVOS: [],
      addQuestionList: []
    }
  },
  mounted() {
    //过滤传递的题型
    if (this.notQuestionType) {
      this.filters.questionType.children = this.filters.questionType.children.filter(type => type.value !== this.notQuestionType)
    }
    this.getKnowledgeTree()
    this.searchQuestionBanks()
    this.getTags()
  },
  methods: {
    selectTypeMenu(index) {
      this.type = index
    },
    delQuestionType(type) {
      this.questionSortList[type].question = []
      this.addQuestionList.forEach((item, index) => {
        if (item.questionType === type) {
          this.addQuestionList.splice(index, 1)
        }
      })
    },
    delQuestion(id) {
      this.addQuestionList.forEach((item, index) => {
        if (item.id === id) {
          this.addQuestionList.splice(index, 1)
        }
      })
      this.skillsData.forEach(item => {
        if (item.value === this.searchQuestionForm.searchForm.abilities[0] && item.selectCount != 0) {
          item.selectCount -= 1
        }
      })
      this.resetQuestion()
      this.addQuestionList.forEach(item => {
        this.questionSortList[item.questionType].question.push(item)
      })
    },
    //切换
    handleCommand(command) {
      this.activeIndex = command
      this.selectMenu(this.activeIndex)
    },
    //自动勾选questionBank
    tickAuto(data) {
      if (data) {
        this.tickData = data
        this.idSet = []
        for (const data of this.tickData) {
          this.idSet = this.idSet.concat(data[1])
          for (const list of this.questionBankList) {
            if (data[0] === list.questionBankId) {
              this.$set(list, 'check', true)
            }
          }
        }
      }
    },
    //整合各个questionBank下的题目
    async questionConformity(data) {
      const questionList = await data.reduce((pre, cur) => {
        return pre.concat(cur.questionForms || cur.questionVOS)
      }, [])
      let map = new Map()
      for (let item of questionList) {
        if (map.has(item.questionBankId)) {
          map.get(item.questionBankId).push(item.questionId)
        } else {
          map.set(item.questionBankId, [item.questionId])
        }
      }
      await this.tickAuto(map)
      this.addQuestionList = this.$deepCopy(questionList)
      this.addQuestionList.forEach(item => {
        this.questionSortList[item.questionDetail.questionType].question.push(item)
        item.stem = item.questionDetail.stem
      })
      this.searchQuestions()
    },
    //自动勾选题目
    tickAutoQuestion() {
      for (const data of this.tickData) {
        for (const bankList of this.questionList) {
          if (data[1].includes(bankList.id)) {
            this.$set(bankList, 'isChecked', true)
          }
        }
      }
    },
    //标签搜索
    toSearchByLabelKnowledge(status) {
      if (status) {
        if (this.searchQuestionForm.searchForm.hasQuestionTag) {
          this.filters.other.children[2].value = []
          this.searchQuestionForm.searchForm.questionTagIds = []
        } else {
          this.searchQuestionForm.searchForm.questionTagIds = this.filters.other.children[2].value
        }
      } else {
        this.filters.other.children[2].value = []
        this.searchQuestionForm.searchForm.questionTagIds = []
        this.searchQuestionForm.searchForm.hasQuestionTag = false
      }
      this.reSearchQuestions()
    },
    //标签搜索
    toFilterLabel() {
      if (this.labelDownKeyword) {
        this.filters.other.children[2].options = this.labeData.filter(res => {
          if (res.label === this.labelDownKeyword) {
            return res
          }
        })
      } else {
        this.filters.other.children[2].options = this.$deepCopy(this.labeData)
      }
    },
    setLabelSearchQuestions() {
      this.filters.other.children[2].value = []
      this.searchQuestionForm.searchForm.questionTagIds = []
      this.searchQuestionForm.searchForm.hasQuestionTag = !this.searchQuestionForm.searchForm.hasQuestionTag
      this.reSearchQuestions()
    },
    setSearchQuestions() {
      this.$refs['knowledge-tree-down'][0].setCheckedKeys([])
      this.searchQuestionForm.searchForm.knowledgePointIds = []
      this.searchQuestionForm.searchForm.hasKnowledge = !this.searchQuestionForm.searchForm.hasKnowledge
      this.reSearchQuestions()
    },
    editorPageExamQuestions() {
      // 编辑试卷页面是  添加题目  获取已选择题目
      if (this.addQuestionList.length <= 0) {
        return this.$message.warning('暂未选择题目!')
      }
      return this.addQuestionList
    },
    setSkillSearchQuestions() {
      this.$refs['skill-tree-down'][0].setCheckedKeys([])
      this.searchQuestionForm.searchForm.abilities = []
      this.searchQuestionForm.searchForm.hasAbility = !this.searchQuestionForm.searchForm.hasAbility
      this.reSearchQuestions()
    },
    paperRelation() {
      if (this.activeIndex === '1') {
        this.stateManual = false
        this.activeIndex = '2'
        this.addQuestionList = []
        this.idSet = []
      } else {
        this.stateManual = false
        this.activeIndex = '1'
        this.addQuestionList = []
        this.idSet = []
      }
    },
    beforeHandle(activeName, oldActiveName) {
      if (this.idSet.length) {
        this.stateManual = true
        return false
      } else {
        this.selectMenu(activeName)
      }
    },
    //全选
    addAbility() {
      this.questionList.map(res => {
        if (!this.idSet.includes(res.id)) {
          this.skillsData.forEach(item => {
            if (item.value === this.searchQuestionForm.searchForm.abilities[0]) {
              item.selectCount += 1
            }
          })
          this.idSet.push(res.id)
          this.addQuestionList.push({ ...res, questionId: res.id })
          this.questionSimpleVOS.push(res)
          res.isChecked = true
        }
      })
    },
    getQuestionBanks() {
      let payload = {
        keyword: '',
        questionName: '',
        questionTagIds: [],
        type: this.activeRootIndex
      }
      questionBankApi.searchByForm(payload).then(res => {
        this.moveQuestionBankList = res.res
      })
    },
    searchQuestionBanks(isRefresh) {
      //搜索题库列表
      if (this.activeIndex === '1') {
        let payload = {
          keyword: this.keyword,
          questionName: this.searchQuestionForm.questionName,
          questionTagIds: this.searchQuestionForm.tagIds,
          type: this.activeRootIndex
        }
        this.notQuestionType ? (payload['notQuestionType'] = this.notQuestionType) : ''
        questionBankApi.searchByForm(payload).then(res => {
          if (res.code === 0 && res.res.length === 0) {
            this.searchQuestionForm.questionBankId = ''
            this.questionListLoading = false
            this.menuLoading = false
            this.questionList = []
          } else if (!isRefresh) {
            this.searchQuestionForm.questionBankId = res.res[0].questionBankId
          }
          this.questionBankList = res.res
          this.tickAuto(this.tickData)
          this.reSearchQuestions()
        })
      } else if (this.activeIndex === '2') {
        this.getKnowledgeTreeByCondition()
        this.reSearchQuestions()
      } else {
        this.getSkillTree()
        this.reSearchQuestions()
      }
    },
    getSkillTree() {
      //获取技能树
      this.skillLoading = true
      abilityApi.getOrgAbility('').then(res => {
        let skillsId = res.res.map(item => {
          return {
            label: item.name,
            value: item.id,
            count: item.questionCount,
            selectCount: 0
          }
        })
        this.skillsData = skillsId
        this.skillDown = skillsId
        this.skillsId = this.skillsData[0].value
        this.searchQuestionForm.searchForm.abilities = [this.skillsId]
        this.skillLoading = false
        this.$nextTick(() => {
          this.$refs['skill-tree'].setCurrentKey(this.skillsId)
        })
      })
    },
    setQuestionSkill(question, index) {
      let abilityIds = this.$refs['skill-tree-update'][0].getCheckedKeys()
      let payload = {
        questionId: question.id,
        abilities: abilityIds
      }
      questionAbilityApi.questionAddSkill(payload).then(res => {
        if (res.res) {
          this.$message.success('技能已变更')
          question.abilities = this.$refs['skill-tree-update'][0].getCheckedNodes().map(item => {
            return {
              abilityName: item.label,
              abilityId: item.value
            }
          })
          this.$forceUpdate()
        }
      })
    },
    async setForm() {
      let payload = { ...this.searchQuestionForm }
      payload.searchForm = { ...this.searchQuestionForm.searchForm }
      this.notQuestionType ? (payload.searchForm.notQuestionType = this.notQuestionType) : ''
      payload.searchForm.questionStatus = payload.searchForm.questionStatus === '' ? null : payload.searchForm.questionStatus
      payload.searchForm.difficultyType = payload.searchForm.difficultyType === '' ? null : payload.searchForm.difficultyType
      payload.searchForm.hasKnowledge = payload.searchForm.hasKnowledge ? false : null
      payload.searchForm.hasAbility = payload.searchForm.hasAbility ? false : null
      payload.searchForm.hasQuestionTag = payload.searchForm.hasQuestionTag ? false : null
      payload.searchForm.hasTestCase = payload.searchForm.hasTestCase ? true : null
      payload.searchForm.questionType = payload.searchForm.questionType === '' ? null : payload.searchForm.questionType
      payload.type = this.activeRootIndex
      return payload
    },
    async searchQuestions() {
      this.questionList = []
      //搜索题目列表
      //判断如果在企业题库未获取到时, 不进行筛选
      if (this.activeRootIndex === '1' && this.activeIndex === '1' && this.questionBankList.length === 0) {
        return
      }
      this.questionListLoading = true
      let payload = await this.setForm()
      questionApi
        .searchByForm(payload, this.pageNum, this.pageSize)
        .then(res => {
          if (res.code === 0) {
            this.total = res.res.total
            this.$nextTick(() => {
              this.questionList = res.res.data.map(item => {
                if (!item.hasOwnProperty('analysis')) {
                  item.analysis = ''
                }
                item.isChecked = this.idSet.includes(item.id)
                if (item.hasOwnProperty('knowledgePoints')) {
                  item.knowledgePointIds = item.knowledgePoints.map(point => {
                    return point.knowledgeId
                  })
                } else {
                  item.knowledgePointIds = []
                }
                if (!item.hasOwnProperty('tagIds')) {
                  item.tagIds = []
                }
                item.modifyTagModel = false
                item.modifyKnowledgeModel = false
                return item
              })
            })
          }
        })
        .finally(() => {
          if (this.tickData) {
            this.tickAutoQuestion()
          }
          this.questionListLoading = false
          this.menuLoading = false
        })
    },
    reSearchQuestions() {
      this.pageNum = 0
      this.total = 0
      this.searchQuestions()
    },
    getKnowledgeTree() {
      //获取知识点树
      questionApi.getKnownLedgeTree().then(res => {
        this.knowledgePointsDown = res.res
        this.knowledgePointsUpdate = res.res
      })
    },
    getKnowledgeTreeByCondition() {
      //获取知识点树通过条件
      let payload = {
        questionName: this.searchQuestionForm.questionName,
        tagIds: this.searchQuestionForm.tagIds
      }
      this.notQuestionType ? (payload['notQuestionType'] = this.notQuestionType) : ''
      questionApi.getKnownLedgeTreeByCondition(payload).then(res => {
        this.knowledgePoints = res.res
      })
    },
    toFilterNode(ref) {
      //知识点树过滤点击触发(左侧)
      this.$refs[ref].filter(this.activeIndex === '2' ? this.knowledgePointKeyword : this.skillKeyword)
      this.$refs[ref].setCheckedNodes([])
    },
    skillToFilterNode(ref) {
      //技能树过滤点击触发(左侧)
      this.$refs[ref][0].filter(this.skillDownKeyword)
      this.$refs[ref][0].setCheckedNodes([])
    },
    skillToFilterNodeDown(ref, keyword) {
      //技能树过滤点击触发(筛选)
      this.$refs[ref][0].filter(keyword)
      this.$refs[ref][0].setCheckedNodes([])
    },
    //根据技能搜索题目
    toSearchByAbility() {
      if (this.searchQuestionForm.searchForm.hasAbility) {
        this.$refs['skill-tree-down'][0].setCheckedKeys([])
      }
      this.searchQuestionForm.searchForm.abilities = this.$refs['skill-tree-down'][0].getCheckedKeys()
      this.reSearchQuestions()
    },
    toFilterNodeDown(ref, keyword) {
      //知识点树过滤点击触发(筛选)
      this.$refs[ref][0].filter(keyword)
      this.$refs[ref][0].setCheckedNodes([])
    },
    filterNode(value, data) {
      //知识点树过滤
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    resetCheckedNodes(ref) {
      //知识点树选择重置
      this.$refs[ref].setCheckedKeys([])
      this.searchQuestionBanks()
    },
    resetCheckedNodesDown(ref) {
      //知识点树选择重置
      if (ref === 'knowledge-tree-down') {
        this.searchQuestionForm.searchForm.knowledgePointIds = []
        this.searchQuestionForm.searchForm.hasKnowledge = false
      } else {
        this.searchQuestionForm.searchForm.abilities = []
        this.searchQuestionForm.searchForm.hasAbility = false
      }
      this.$refs[ref][0].setCheckedKeys([])
      this.searchQuestionBanks()
    },
    toSearchByKnowledge() {
      if (this.searchQuestionForm.searchForm.hasKnowledge) {
        this.$refs['knowledge-tree-down'][0].setCheckedKeys([])
      }
      this.searchQuestionForm.searchForm.knowledgePointIds = this.$refs['knowledge-tree-down'][0].getCheckedKeys()
      this.reSearchQuestions()
    },
    handleClickTreeNode(data, checkData) {
      let knowledgePoints = []
      checkData.checkedNodes.forEach(item => {
        knowledgePoints.push(item.id)
      })
      this.searchQuestionForm.searchForm.knowledgePointIds = knowledgePoints
      this.reSearchQuestions()
    },
    getTags() {
      let payload = {
        keyword: '',
        typeId: this.ytConstant.tagType.QUESTION
      }
      this.notQuestionType ? (payload['notQuestionType'] = this.notQuestionType) : ''
      tagApi.searchTagByTypeId(payload).then(res => {
        if (res.code === 0) {
          this.tagOptions = res.res
          let tags = res.res.map(item => {
            return {
              label: item.name,
              value: item.tagId
            }
          })
          this.filters.other.children[2].options = tags
          this.labeData = tags
          this.tagsUpdate = tags
          this.$forceUpdate()
        }
      })
    },
    selectRootMenu(index) {
      this.activeRootIndex = index
      this.activeIndex = '1'
      this.menuLoading = true
      this.total = 0
      this.searchQuestionForm.questionName = ''
      this.searchQuestionForm.tagIds = []
      this.searchQuestionForm.searchForm.knowledgePointIds = []
      this.searchQuestionForm.searchForm.questionType = ''
      this.searchQuestionForm.searchForm.questionStatus = ''
      this.searchQuestionForm.searchForm.difficultyType = null
      this.searchQuestionForm.searchForm.useCountDesc = ''
      this.searchQuestionForm.searchForm.questionTagIds = []
      this.searchQuestionBanks()
    },
    handleClickQuestionBank(index, id) {
      this.isSelectAll = false
      this.activeBankIndex = index
      this.searchQuestionForm.questionBankId = id
      this.pageSize = 10
      this.pageNum = 0
      this.searchQuestions()
    },
    selectMenu(index) {
      this.activeIndex = index
      this.total = 0
      this.searchQuestionForm.questionName = ''
      this.searchQuestionForm.tagIds = []
      this.searchQuestionForm.searchForm.knowledgePointIds = []
      this.searchQuestionForm.searchForm.questionType = ''
      this.searchQuestionForm.searchForm.questionStatus = ''
      this.searchQuestionForm.searchForm.difficultyType = null
      this.searchQuestionForm.searchForm.useCountDesc = ''
      this.searchQuestionForm.searchForm.questionTagIds = []
      this.searchQuestionForm.searchForm.hasAbility = false
      this.searchQuestionForm.searchForm.abilities = []
      if (index !== '1') {
        this.searchQuestionForm.questionBankId = null
      }
      this.searchQuestionBanks()
    },
    handleNodeClick(data) {
      this.searchQuestionForm.searchForm.abilities = [data.value]
      this.reSearchQuestions()
    },
    handleSkillDropdownChange(visible, index) {
      if (!visible) {
        this.skillUpdateKeyword = ''
        this.$refs['skill-tree-update'][0].setCheckedKeys([])
        this.$set(this.questionList[index], 'modifySkillModel', false)
        this.toFilterNodeUpdate('skill-tree-update', this.skillUpdateKeyword, index)
      } else {
        this.$set(this.questionList[index], 'modifySkillModel', true)
        this.$nextTick(() => {
          if (this.questionList[index].hasOwnProperty('abilities')) {
            this.$refs['skill-tree-update'][0].setCheckedKeys(this.questionList[index].skillIds)
          }
        })
      }
    },
    handleClickFilter(key, value) {
      this.searchQuestionForm.searchForm[key] = value
      this.reSearchQuestions()
    },
    sortByUseCount() {
      if (this.searchQuestionForm.searchForm.useCountDesc === '') {
        this.searchQuestionForm.searchForm.useCountDesc = true
      } else if (this.searchQuestionForm.searchForm.useCountDesc) {
        this.searchQuestionForm.searchForm.useCountDesc = false
      } else {
        this.searchQuestionForm.searchForm.useCountDesc = ''
      }
      this.searchQuestions()
    },
    selectDifficulty(command) {
      this.searchQuestionForm.searchForm.difficultyType = command
      this.reSearchQuestions()
    },
    selectQuestionTag(command) {
      let tagIndex = this.searchQuestionForm.searchForm.questionTagIds.indexOf(command)
      if (tagIndex !== -1) {
        this.searchQuestionForm.searchForm.questionTagIds.splice(tagIndex, 1)
      } else {
        this.searchQuestionForm.searchForm.questionTagIds.push(command)
      }
      this.reSearchQuestions()
    },
    changePage(pageNum) {
      this.pageNum = pageNum - 1
      this.searchQuestions()
    },
    changePageSize(pageSize) {
      this.pageSize = pageSize
      this.searchQuestions()
    },
    handleCheckAllChange(val) {
      if (val) {
        this.questionSelection = this.questionList.map(item => {
          item.isChecked = true
          return item.id
        })
      } else {
        this.questionList.map(item => {
          item.isChecked = false
        })
        this.questionSelection = []
      }
      this.isIndeterminate = false
      this.$forceUpdate()
    },
    handleCheckQuestion(value, question) {
      if (Array.isArray(value) && value.includes(undefined)) {
        return
      }
      let find = this.addQuestionList.findIndex(q => q.questionId === question.id)
      if (value) {
        this.questionSelection.push(question.id)
        this.idSet.push(question.id)
        this.questionSimpleVOS.push(question)
        if (find === -1) {
          this.addQuestionList.push({ ...question, questionId: question.id })
          this.skillsData.forEach(item => {
            if (item.value === this.searchQuestionForm.searchForm.abilities[0]) {
              item.selectCount += 1
            }
          })
        }
      } else {
        if (find !== -1) {
          this.addQuestionList = this.addQuestionList.filter(q => q.questionId !== this.addQuestionList[find].questionId)
        }
        this.idSet.splice(this.idSet.indexOf(question.id), 1)
        this.questionSimpleVOS.forEach((s, index) => {
          if (s.id === question.id) {
            this.skillsData.forEach(item => {
              if (item.value === this.searchQuestionForm.searchForm.abilities[0] && item.selectCount != 0) {
                item.selectCount -= 1
              }
            })
            this.questionSimpleVOS.splice(index, 1)
          }
          this.questionSelection.splice(this.questionSelection.indexOf(question.id), 1)
        })
        let checkedCount = this.questionSelection.length
        this.isSelectAll = checkedCount === this.questionList.length
        this.isIndeterminate = checkedCount > 0 && checkedCount < this.questionList.length
      }
      this.resetQuestion()
      this.addQuestionList.forEach(item => {
        this.questionSortList[item.questionType].question.push(item)
      })
    },
    resetQuestion() {
      this.questionSortList = [
        { label: '判断题', question: [] },
        { label: '单选题', question: [] },
        { label: '多选题', question: [] },
        { label: '填空题', question: [] },
        { label: '简答题', question: [] },
        { label: '代码题', question: [] }
      ]
    },
    showPaperInfo(question) {
      if (!this.questionPaperInfo.hasOwnProperty(question.id)) {
        let loading = null
        if (question.useCount > 2) {
          loading = this.$onLoading('#paper-info-' + question.id)
        }
        this.$set(this.questionPaperInfo, question.id, true) //先放一个空的, 如果悬停结束接口不结束下次进来不会走接口
        questionApi
          .getQuestionPaperInfo(question.id)
          .then(res => {
            this.$set(this.questionPaperInfo, question.id, res.res)
          })
          .finally(() => {
            if (question.useCount > 2) {
              loading.close()
            }
          })
      }
    },
    showAudit(questionId) {
      if (!this.questionAuditInfo.hasOwnProperty(questionId)) {
        let loading = this.$onLoading('#audit-info-' + questionId)
        this.$set(this.questionAuditInfo, questionId, true) //先放一个空的, 如果悬停结束接口不结束下次进来不会走接口
        questionApi
          .getQuestionAuditInfo(questionId)
          .then(res => {
            this.$set(this.questionAuditInfo, questionId, res.res)
          })
          .finally(() => {
            loading.close()
          })
      }
    },
    toAudit(question) {
      this.toAuditQuestionId = question.id
      this.$refs.auditQuestionDialog.open()
    },
    auditQuestion() {
      questionApi.auditQuestion(this.toAuditQuestionId, 0).then(res => {
        if (res.res === true) {
          this.$message.success('审核成功')
          this.searchQuestions()
        }
      })
    },
    toDelete(question) {
      if (question) {
        this.toDeleteQuestionIds = [question.id]
      } else {
        if (this.questionSelection.length === 0) {
          this.$message.warning('请先选择题目')
          return
        } else {
          this.toDeleteQuestionIds = this.questionSelection
        }
      }
      this.$refs.deleteQuestionDialog.open()
    },
    calculateSize(width) {
      if (width <= 1440) {
        this.showSize = 1
      } else if (width > 1440 && width < 1920) {
        this.showSize = 2
      } else {
        this.showSize = 3
      }
    },
    updateQuestionBanks(data, diffculty, answer) {
      this.questionList.forEach((item, index) => {
        if (data.id === item.id) {
          item.stem = data.stem
          item.analysis = data.analysis
            .replace(/<[pre /pre> code /code]+>/g, '')
            .replace(/<br>/g, '\n')
            .replace(/&lt;/g, '<')
            .replace(/&gt;/g, '>')
            .replace(/&amp;/g, '&')
            .replace(/&nbsp;/g, ' ')
          item.difficulty = diffculty
          if (item.questionType === 0) {
            this.searchQuestionBanks(true)
          } else if (item.questionType === 1) {
            item.options.forEach((s, n) => {
              const arrayE = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J']
              if (s.correct === true) {
                item.questionAnswer = arrayE[n]
              }
            })
          } else if (item.questionType === 2) {
            let str = ''
            item.options.forEach((s, n) => {
              const arrayE = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J']
              if (s.correct === true) {
                str += arrayE[n]
              }
            })
            item.questionAnswer = str
          } else if (item.questionType === 3 || item.questionType === 5) {
            this.searchQuestionBanks(true)
          } else if (item.questionType === 4) {
            item.answer = answer
            item.questionAnswer = answer
          }
          if (item.questionBankId !== data.questionBankId) {
            this.questionList.splice(index, 1)
            this.questionBankList.forEach(i => {
              if (i.questionBankId === data.questionBankId) {
                i.questionCount += 1
              } else if (this.searchQuestionForm.questionBankId === i.questionBankId) {
                i.questionCount -= 1
              }
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/variables';
@import '~@/theme/filterDropdownMenu';
.node__label {
  font-size: 12px;
  font-weight: bold;
  line-height: 28px;
  color: #000000;
}
.check-right {
  height: calc(100% - 128px);
  width: 259px;
  background: #ffffff;
  position: absolute;
  top: 56px;
  right: 10px;
  z-index: 999;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 6px 0 0 6px;
  .left-svg {
    position: absolute;
    z-index: 999;
    color: #999999;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
  }
  .right-drawer {
    height: 100%;
    .right-svg {
      transform: rotate(180deg);
      position: absolute;
      color: #999999;
      z-index: 999;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
    }
    .header {
      height: 50px;
      padding: 20px 20px 0;
      span {
        height: 20px;
        font-size: 14px;
        line-height: 20px;
        color: #448bff;
      }
      ::v-deep.el-menu-item {
        padding: 0 5px;
        font-size: 12px;
        color: #888888;
        line-height: 20px !important;
      }
      ::v-deep.el-menu-item.is-active {
        color: #000000 !important;
        border-bottom: 2px solid #409eff;
      }
    }
    .line {
      width: 260px;
      height: 1px;
      background: #e2e4e8;
      margin-bottom: 10px;
    }
    .content {
      height: calc(100% - 70px);
      overflow-y: auto;
      .question-type {
        .question-sort {
          margin-bottom: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          .question-title {
            display: flex;
            width: 85%;
            font-size: 12px;
            line-height: 17px;
            color: #000000;
          }
          .del {
            width: 15%;
            svg {
              font-size: 16px;
              color: #999999;
              margin-left: 7px;
            }
          }
        }
        .q-style {
          padding-bottom: 10px;
          border-bottom: 1px solid #f0f0f0;
          &:not(:first-child) {
            margin-top: 10px;
          }
        }
        .type {
          width: 3px;
          height: 10px;
          background: #448bff;
          opacity: 1;
          border-radius: 2px;
          /*margin-top: 8px;*/
          margin-right: 5px;
        }
        span {
          font-size: 14px;
          font-weight: 400;
          color: #000000;
        }
      }
      .question-info {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-bottom: 20px;
        padding: 0 20px;
        .question-title {
          display: flex;
          width: 85%;
          font-size: 12px;
          line-height: 17px;
          color: #000000;
        }
        .del {
          width: 15%;
          svg {
            font-size: 16px;
            color: #999999;
            margin-left: 7px;
          }
        }
      }
    }
  }
}

.selectStyle {
  background: #448bff;
  svg {
    fill: #ffffff;
  }
}
.prompt-info {
  ::v-deep .el-dialog__header {
    padding: 20px 20px 20px !important;
    .flexStyle(flex, center, center);
  }
  .prompt {
    .flexStyle(flex, center, center);
    flex-direction: column;
    svg {
      fill: #ff5050;
      margin-right: 5px;
    }
    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      color: #000000;
    }
    .format {
      .flexStyle(flex, center, center);
      span {
        white-space: nowrap;
        margin-right: 12px;
      }
      .input {
        width: 332px;
        height: 37px;
        margin-left: 12px;
      }
      ::v-deep .el-input__inner {
        height: 37px;
        line-height: 37px;
      }
      &:first-child {
        margin-bottom: 30px;
      }
    }
  }
  .prompt-bottom {
    width: 100%;
    .flexStyle(flex, center, center);
    .el-button {
      width: 80px;
    }
  }
}
.yt-main {
  color: #000000;
  background: #ffffff !important;
  padding: 0;
  width: 100% !important;
  .yt-header {
    .el-button {
      width: 80px;
    }
  }
  .yt-container {
    padding: 10px 10px 0;
    background-color: #f8f8f8;
    .flexStyle(flex, space-between);
    .question-bank-content {
      background-color: #ffffff;
      width: 280px;
      height: 100%;
      .yt-menu {
        ::v-deep .el-tabs__nav-wrap::after {
          content: none;
        }
        ::v-deep .el-dropdown-menu__item {
          height: 28px;
          line-height: 28px;
          font-size: @small;
          font-weight: bold;
          &:hover {
            color: #448bff;
            background-color: #edf4ff;
          }
        }
        ::v-deep .el-tabs__content {
          margin-bottom: 7px;
        }
        ::v-deep .tabs__header {
          margin: 0;
        }
        .flexStyle(flex, space-between);
        height: 50px;
        padding: 6px 30px 0;
        margin: 0 !important;
        border-bottom: 1px solid #e2e4e8 !important;
        .label {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &:before {
          display: none;
        }
        &:after {
          display: none;
        }
        .el-menu-item {
          margin: 0 !important;
          color: #dbdbdb !important;
          display: flex;
          align-items: center;
          &:hover {
            color: #000000 !important;
            .icon {
              background-color: #448bff;
            }
          }
        }
        .is-active {
          color: #000000 !important;
          border-bottom: 4px solid #409eff;
          .icon {
            background-color: #448bff;
          }
        }
        .icon {
          .flexStyle(flex, center);
          width: 20px;
          height: 20px;
          background-color: #dbdbdb;
          border-radius: 50%;
          margin-right: 6px;
          svg {
            width: 14px;
            height: 14px;
            color: #ffffff;
            border-radius: unset;
            margin-right: 0;
          }
        }
        &.is-platform {
          justify-content: center;
          .el-menu-item {
            border-bottom: none;
          }
        }
      }
      .title {
        .flexStyle(flex);
        height: 50px;
        .icon {
          .flexStyle(flex, center);
          width: 20px;
          height: 20px;
          background-color: #dbdbdb;
          border-radius: 50%;
          margin-right: 6px;
          svg {
            width: 14px;
            height: 14px;
            color: #ffffff;
            border-radius: unset;
            margin-right: 0;
          }
        }
      }
      .search {
        margin: 12px 0;
        padding: 0 10px;
        height: 32px;
        ::v-deep .el-input__suffix {
          right: 15px;
        }
      }
      .footer {
        .flexStyle(flex, center, center);
        height: 55px;
        border-top: 1px solid #e2e4e8;
        .el-button {
          width: 78px;
          height: 32px;
          line-height: 6px;
        }
      }
    }
    .question-content {
      background-color: #ffffff;
      width: calc(100% - 290px);
      height: 100%;
    }
  }
}
//题库列表
.list-container {
  overflow-y: auto;
  .list {
    .item {
      .flexStyle(flex, flex-start);
      height: 56px;
      border-bottom: 1px solid #f0f2f3;
      &:hover {
        background-color: #f0f0f0;
      }
      .cover {
        width: 32px;
        height: 32px;
        margin: 0 0 0 10px;
        border-radius: 7px;
        ::v-deep .image-slot {
          height: 100%;
          .flexStyle(flex, center, center);
        }
      }
      .row {
        .flexStyle(flex, space-between);
        color: #666666;
        font-size: 10px;
        padding: 0 4px 0 13px;
        &:nth-of-type(1) {
          margin-bottom: 3px;
        }
        p {
          font-size: @small;
          font-weight: bold;
          color: #000000;
        }
        div {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .tab-container {
        .flexStyle(flex, flex-start);
        .item {
          max-width: 100px;
          height: 18px;
          line-height: 18px;
          background: #edf4ff;
          border-radius: 10px;
          padding: 0 8px;
          color: #438bff;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .dots {
          margin-left: 6px;
          cursor: pointer;
          svg {
            vertical-align: middle;
            fill: #438bff;
          }
        }
      }
    }
    .active {
      background-color: #f0f0f0;
    }
  }
  ::v-deep .el-tree-node__children {
    overflow: visible;
  }
  ::v-deep .el-tree-node__content {
    flex-grow: 1;
  }
  .tree-node {
    width: calc(100% - 48px);
    .flexStyle(flex, space-between, center);
    flex-shrink: 0;
    p {
      color: #000000;
      font-size: @small;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    div {
      font-size: 10px;
    }
  }
}
//题库列表悬停标签
.tab-list {
  display: flex;
  flex-wrap: wrap;
  .item {
    font-size: 10px;
    height: 18px;
    line-height: 18px;
    background: #edf4ff;
    border-radius: 10px;
    color: #438bff;
    padding: 0 8px;
    margin-right: auto;
    margin-bottom: 10px;
  }
}
//筛选列表
.filter-container {
  border-bottom: 1px solid #e2e4e8;
  padding: 16px 20px 0;
  position: relative;
  .pack-up {
    position: absolute;
    width: 53px !important;
    height: 25px;
    line-height: 25px;
    background: #f7f7f7;
    border-radius: 13px;
    border: 1px solid #e6e6e6;
    cursor: pointer;
    right: 25px;
    top: 13px;
    .flexStyle(flex, flex-start, center);
    p {
      margin-left: 8px;
      font-size: @small;
      width: 24px !important;
      user-select: none; //不被选中
    }

    svg {
      width: 9px;
      height: 14px;
      margin-left: 4px;
      margin-top: 14px;
      margin-right: 6px;
      transition: all 0.2s ease-in-out;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    .active {
      -webkit-transform: translateY(-50%) rotate(180deg);
      transform: translateY(-50%) rotate(180deg);
    }
  }
  .row {
    .flexStyle(flex, flex-start);
    height: 20px;
    margin-bottom: 16px;
    color: #000000;
    &:last-child {
      margin-bottom: 0;
    }
    .label {
      font-size: @small;
      margin-right: 20px;
      height: 20px;
      line-height: 20px;
    }
    .item {
      height: 20px;
      line-height: 20px;
      margin: 0 8px;
      padding: 0 15px;
      font-size: @small;
      cursor: pointer;
    }
    .default-item {
      &:hover {
        border-radius: 11px;
        background: #438bff;
        color: #ffffff;
      }
    }
    .active {
      border-radius: 11px;
      background: #438bff;
      color: #ffffff;
    }
    .checkbox-container {
      .flexStyle(flex, flex-start, center);
      border-left: 1px solid #d8d8d8;
      margin-left: 17px;
      .el-checkbox {
        margin-left: 40px;
        margin-right: 0;
        ::v-deep .el-checkbox__label {
          font-size: @small;
          padding-left: 6px;
          color: #000000 !important;
        }
      }
    }
    .other-item {
      padding: 0;
      margin: 0 22px 0 8px;
      -moz-user-select: none; /*火狐*/
      -webkit-user-select: none; /*webkit浏览器*/
      -ms-user-select: none; /*IE10*/
      user-select: none; //禁止双击选中文字
      svg {
        color: #a6a6a6;
      }
    }
    .asc {
      transform: rotate(180deg);
    }
    .select {
      font-size: @small;
      &:hover {
        color: #438bff;
        i {
          transform: rotate(180deg);
        }
      }
    }
    .el-dropdown {
      .flexStyle(flex, flex-start, center);
      height: 20px;
      line-height: 20px;
      color: #000000;
    }
    .el-icon-arrow-down {
      font-size: @small;
      color: #999999 !important;
      transition: all 0.2s ease-in-out;
    }
  }
}
//全选
.select-all {
  .flexStyle(flex, flex-end, center);
  height: 36px;
  border-bottom: 1px solid rgba(226, 228, 232, 0.3);
  padding-left: 20px;
  .el-checkbox {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    ::v-deep .el-checkbox__label {
      font-size: @small;
      color: rgba(0, 0, 0, 0.85) !important;
    }
  }
}
//题目列表
.question-container {
  width: 100%;
  .list {
    background-color: #f8f8f8;
  }
  .item {
    display: flex;
    background-color: #ffffff;
    margin-bottom: 9px;
    padding: 0 0 0 20px;
    .checkbox {
      height: 16px;
      margin-top: 19px;
      margin-right: 4px;
      ::v-deep .el-checkbox__label {
        display: none;
      }
    }
    .header {
      .flexStyle(flex, flex-start);
      margin: 16px 0 6px;
      .question-type {
        width: 23px;
        height: 23px;
        line-height: 22px;
        text-align: center;
        color: #ffffff;
        font-weight: bold;
        background: #438bff;
        border-radius: 50%;
        margin-left: 4px;
        margin-right: 16px;
        font-size: 14px;
      }
      .audit {
        .flexStyle(flex, center, center);
        height: 23px;
        overflow: hidden;
      }
      svg {
        font-size: 68px;
      }
    }
    .footer {
      height: 20px;
      margin: 10px 0;
      font-size: 14px;
      padding-right: 30px;
      div {
        .flexStyle(flex, flex-start, center);
      }
      p {
        height: 18px;
        line-height: 20px;
        border-right: 1px solid #d8d8d8;
        padding-right: 18px;
        margin-right: 16px;
        cursor: pointer;
        &:hover {
          color: #438bff;
        }
      }
      .inactive {
        cursor: initial;
        &:hover {
          color: #000;
        }
      }
      svg {
        color: #999999;
        &:hover {
          color: #438bff;
        }
      }
      .button {
        color: #666666;
        margin-left: 17px;
        cursor: pointer;
        &:hover {
          color: #448bff;
          svg {
            color: #448bff;
          }
        }
        svg {
          font-size: 14px;
          margin-right: 6px;
        }
      }
    }
  }
  .page {
    //height: 40px;
    background-color: #ffffff;
    width: 100%;
    padding: 0 30px;
    margin-top: 10px;
    .yt-page {
      margin-top: 0;
    }
  }
}
::v-deep .el-checkbox__input {
  .el-checkbox__inner {
    width: 16px;
    height: 16px;
    &:after {
      left: 5px;
      top: 2px;
    }
  }
}
.empty {
  .flexStyle(flex, center, center);
  background-color: #ffffff;
  svg {
    font-size: 100px;
  }
}
</style>
<style lang="less">
.tk-select-down {
  margin: 4px 0 0 !important;
  .popper__arrow {
    display: none;
  }
  .el-select-dropdown__list {
    padding: 0;
  }
}
.paper-info {
  padding: 5px 0 0;
  .paper-info-container {
    max-height: 150px;
    overflow-y: auto;
  }
  .paper-info-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    padding-left: 12px;
    padding-right: 14px;
    color: #090909;
    font-size: 12px;
    &:hover {
      background-color: #f0f0f0;
    }
    p {
      &:nth-of-type(1) {
        max-width: 140px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
.code-tab-container {
  width: calc(100% - 30px);
}
.knowledge {
  font-size: 14px;
  height: 40px;
  padding-left: 10px;
  line-height: 40px;
  &:hover {
    background-color: #edf4ff;
  }
}
.el-dropdown-menu {
  max-height: fit-content !important;
}
.checkBox {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  height: calc(100vh - 400px);
  overflow: auto;
}
</style>
