import config from '../config/config'
import { axios } from './index'
const questionAbilityUrl = config.baseUrl + '/question/api/v2/question/ability'

export default {
  //搜索题库列表（简要信息）
  questionAddSkill(payload) {
    return axios.post(`${questionAbilityUrl}/add?questionIds=${payload.questionId}&abilities=${payload.abilities}`)
  },
  //题目批量添加技能
  questionAddAbility(payload) {
    return axios.post(`${questionAbilityUrl}/add/batch?questionIds=${payload.questionIds}&abilities=${payload.abilities}`)
  }
}
